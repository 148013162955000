/* apps/outdoorPro/src/app/home/home.component.scss */
.home-logo {
  height: auto;
  width: 80px;
  min-width: 80px;
}
@media screen and (min-width: 1200px) {
  .home-logo {
    width: 140px;
    min-width: 140px;
  }
}
.main-content {
  margin-bottom: 40px;
}
.pre-title {
  color: rgb(34, 91, 58);
  font-weight: 600;
}
@media screen and (min-width: 1200px) {
  .pre-title {
    font-size: 18px;
  }
}
h1 {
  margin-top: 5px;
  margin-bottom: 20px;
  color: var(--ion-color-primary);
  font-weight: 600;
  font-size: 24px;
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 34px;
    margin-bottom: 30px;
  }
}
.decathlon-logo {
  max-width: 200px;
  width: 100%;
  height: auto;
}
.homepage-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
.homepage-wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 50px;
}
@media screen and (max-width: 768px) {
  .homepage-wrapper > div {
    padding: 20px;
  }
}
.homepage-wrapper > div > div {
  width: 100%;
}
.sports h2 {
  color: var(--ion-color-primary);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}
@media screen and (min-width: 1200px) {
  .sports h2 {
    font-size: 24px;
  }
}
.quick-links-title {
  margin-top: 30px;
  color: var(--ion-color-primary);
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .quick-links-title {
    font-size: 24px;
  }
}
.quick-links app-icon-block {
  display: block;
  margin-bottom: 8px;
}
@media screen and (min-width: 768px) {
  .quick-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
}
@media screen and (min-width: 1200px) {
  .quick-links {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 28px;
  }
}
.social-media a {
  font-size: 3em;
  margin-right: 10px;
  cursor: pointer;
}
.social-media a:last-of-type {
  margin-right: 0;
}
.social-media a svg {
  height: 40px;
}
.social-media a ion-icon {
  height: 40px;
}
.decathlon-banner {
  background-color: var(--ion-color-primary);
  border-radius: 10px;
  display: flex;
  letter-spacing: 0;
  overflow: hidden;
  flex-direction: column;
}
@media screen and (min-width: 1200px) {
  .decathlon-banner {
    flex-direction: row;
  }
}
.decathlon-banner__logo {
  padding: 10px 25px;
}
.decathlon-banner__image {
  display: flex;
  position: relative;
  flex: 1;
  overflow: hidden;
}
.decathlon-banner__image svg {
  z-index: 10;
  position: absolute;
}
.decathlon-banner__image svg.desktop {
  display: none;
  height: 100%;
  width: auto;
}
.decathlon-banner__image svg.desktop.first {
  left: 0;
  top: 0;
}
.decathlon-banner__image svg.desktop.last {
  right: 0;
  top: 0;
}
@media screen and (min-width: 1200px) {
  .decathlon-banner__image svg.desktop {
    display: block;
  }
}
.decathlon-banner__image svg.mobile {
  width: 100%;
  height: auto;
}
.decathlon-banner__image svg.mobile.first {
  left: 0;
  top: 0;
}
.decathlon-banner__image svg.mobile.last {
  left: 0;
  bottom: 0;
}
@media screen and (min-width: 1200px) {
  .decathlon-banner__image svg.mobile {
    display: none;
  }
}
.decathlon-banner__image img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .decathlon-banner__image img {
    height: 300px;
  }
}
@media screen and (min-width: 1200px) {
  .decathlon-banner__image img {
    position: absolute;
    height: 100%;
  }
}
.decathlon-banner__content {
  line-height: 1.2;
  padding: 20px 20px 20px 0;
  color: #fff;
  margin-right: auto;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 1200px) {
  .decathlon-banner__content {
    max-width: 40%;
  }
}
.decathlon-banner__content h2 {
  margin-top: 0;
  font-weight: 600;
  font-size: 24px;
}
@media screen and (min-width: 1200px) {
  .decathlon-banner__content h2 {
    font-size: 34px;
  }
}
.decathlon-banner__content p {
  margin: 0;
  font-size: 16px;
}
@media screen and (min-width: 1200px) {
  .decathlon-banner__content p {
    font-size: 20px;
  }
}
.decathlon-banner__sponsor {
  background-color: var(--ion-color-secondary);
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.decathlon-banner__sponsor p {
  font-size: 13px;
  margin: 10px 0;
  font-weight: 600;
  color: var(--ion-color-primary);
}
@media screen and (min-width: 1200px) {
  .decathlon-banner__sponsor {
    padding: 30px;
    padding-left: 0;
  }
}
.decathlon-banner__sponsor ion-button {
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 16px;
  margin: 0;
}
.decathlon-banner__sponsor ion-button ion-icon {
  padding-left: 5px;
}
/*# sourceMappingURL=home.component.css.map */
